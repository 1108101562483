const categoryOrdering: any = [
    {
        "NUTRITION & GUT HEALTH": ["GUT BRAIN AXIS", "MACROS", "LIPIDS", "MICROBIOME", "MICRONUTRIENTS"]
    },
    {
        "LONGEVITY": ["DETOX PATHWAYS", "TOXIN SENSITIVITIES", "IMMUNE FUNCTION", "HEART HEALTH", "ANTIOXIDANTS"]
    },
    {
        "SLEEP": ["ONSET", "CIRCADIAN RHYTHM", "SLEEP DURATION", "SLEEP DISRUPTION", "SLEEP MOVEMENT", "MELATONIN"]
    },
    {
        "STRESS RESPONSE": ["CORTISOL", "MOOD REGULATION"]
    },
    {
        "METABOLIC HEALTH": ["HUNGER SIGNALING", "BLOOD SUGAR", "EXERCISE TOLERANCE", "WORKOUT RESPONSE"]
    },
    {
        "HORMONES": ["PROGESTERONE", "TESTOSTERONE", "ESTROGEN", "THYROID"]
    }
]

export const getNextSubLevel = (subLevel: string, id:any) => {
    if (subLevel !== 'THYROID') {
        let nextItem: string = '';
        // outer: for (let key in item) {
        //     inner: for (const itemSubLevel of item[key]) {
        //         break outer;
        //     }
        // }
        categoryOrdering.map((item: any) => {
            Object.keys(item).map((key) => {
                item[key].map((itemSubLevel: any, index: number) => {
                    if (itemSubLevel === subLevel) {
                        if (item[key].length === index + 1) {
                            const nextArrItem:any = categoryOrdering[categoryOrdering.indexOf(item) + 1]
                            const nextElem:any = nextArrItem[Object.keys(nextArrItem)[0]][0]
                            nextItem = `/categories/${id}/${Object.keys(nextArrItem)[0]}/${nextElem}`
                        }
                        else nextItem = `/categories/${id}/${key}/${item[key][index + 1]}`
                    }
                })
            })
        })
        return nextItem;
    }
    return false
}
export const getPreviousSubLevel = (subLevel: string, id:any) => {
    // console.log(subLevel, id);
    if (subLevel !== 'GUT BRAIN AXIS') {
        let prevItem: string = '';
        categoryOrdering.map((item: any) => {
            Object.keys(item).map((key) => {
                item[key].map((itemSubLevel: any, index: number) => {
                    if (itemSubLevel === subLevel) {
                        if (index === 0) {
                            const nextArrItem:any = categoryOrdering[categoryOrdering.indexOf(item) - 1]
                            const lastElem:any = nextArrItem[Object.keys(nextArrItem)[0]][nextArrItem[Object.keys(nextArrItem)[0]].length - 1]
                            prevItem = `/categories/${id}/${Object.keys(nextArrItem)[0]}/${lastElem}`
                        }
                        else prevItem = `/categories/${id}/${key}/${item[key][index - 1]}`
                    }
                })
            })
        })
        return prevItem;
    }
    return false
}
