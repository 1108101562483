import { Column } from "react-table";
import {
  LoginSchema,
  TransactionSchema,
  FileUploadSchema,
  ResetPasswordSchema,
  KitUpdateSchema,
  ForgetPasswordSchema,
  Columns,
  CheckoutSchema,
  Option,
  LabFileUploadSchema
} from "types";
export const LOGININITIALVALUES: LoginSchema = {
  email: "",
  password: "",
};
export const FORGETPASSWORD: ForgetPasswordSchema = {
  email: "",
};
export const RESETINITIALVALUES: ResetPasswordSchema = {
  new_password1: "",
  new_password2: "",
};
export const PATHS = {
  LOGIN: "/login",
  UPLOADREPORT: "/upload",
  CLIENT: "/reports",
  CATEGORIES: "/categories",
  CATEGORIESDETAILS: "/:category/:sub_category",
  DEFAULT: "*",
  FORGETPASSWORD: "/forget-password",
  RESETPASSWORD: "/password/reset/confirm/",
  ORDERKITS:"/order",
  KITSSTATUS:"/processing",
  UPDATEKITSTATUS:"/register",
  LABREPORTSUPLOAD:"/lab-reports-upload",
};
export const Links = [
  {name:"Upload", link:"/upload"},
  { name: "Reports", link: "/reports" },
  { name: "Order", link: "/order" },
  { name: "My Account", link: "/update-password" },
  //{ name: "Processing", link: "/processing" },
];
export const AuthLinks = [
  { name: "Contact", link: "/contact" },
];
export const UserLinks = [
  { name: "My Reports", link: "/client/reports/:id" },
  { name: "Update Kit Status", link: "/register" }
];
export const TRANSACTIONINITIALVALUES: TransactionSchema = {
  cardName: "",
  cardNumber: "",
  cvv: "",
  expiryDate: "",
};

export const FILEUPLOADVALUES: FileUploadSchema = {
  inputFile: "",
  clientName: "",
  clientEmail: "",
  serviceType: "",
};
export const LABFILEUPLOADVALUES: LabFileUploadSchema = {
  inputFile: "",
};
export const KITUPDATEVALUES: KitUpdateSchema = {
  client_fname: "",
  client_lname: "",
  client_email: "",
  client_kit_no: "",
};
export const CHECKOUTVALUES: CheckoutSchema = {
  clientEmail:"",
  firstName:"",
  lastName: "",
  phoneNumber:"",
  shippingAddress1: "",
  shippingAddress2: "",
  city: "",
  state: "Alabama",
  zipCode: "",
  country: "US",
  serviceType:"",
  mode: "domestic",
};

export const COLUMNS: Column<Columns>[] = [
  { Header: "Value", accessor: "value" as keyof Columns },
  { Header: "Gene", accessor: "gene" as keyof Columns },
  { Header: "rsID", accessor: "rsID" as keyof Columns },
  { Header: "Genotype", accessor: "genotype" as keyof Columns },
  {
    Header: "Coaching Notes",
    accessor: "indication" as keyof Columns,
  },
  //!always keep genotype note the last element of this array
  { Header: "Genotype Note", accessor: "genotype_note" as keyof Columns },
];
export const CLIENTTABLECOLUMN: Column<Columns>[] = [
  {
    Header: "Client Name",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Report",
    accessor: "col3" as keyof Columns,
  },
  //!always keep Client ID the last element of this array

  {
    Header: "Client ID",
    accessor: "col2" as keyof Columns,
  },
];
export const ORDERKITSTABLE: Column<Columns>[] = [
  {
    Header: "Order Date",
    accessor: "col1" as keyof Columns,
  },
  {
    Header: "Name",
    accessor: "col2" as keyof Columns,
  },
  {
    Header: "Email",
    accessor: "col3" as keyof Columns,
  },
  {
    Header: "Status",
    accessor: "col4" as keyof Columns,
  },
  {
    Header: "Tracking No",
    accessor: "col5" as keyof Columns,
  },
  {
    Header: "File Url",
    accessor: "col6" as keyof Columns
  }
];
export const SUBLEVELVIDEOS = [
  {
    name: "GRAINS",
    link: "https://player.vimeo.com/video/863766772?h=3efb6116e2",
  },
  {
    name: "GLUTEN & GRAINS",
    link: "https://player.vimeo.com/video/863766772?h=3efb6116e2",
  },
  {
    name: "DAIRY",
    link: "https://player.vimeo.com/video/863721447?h=b3bdbc67cb",
  },
  {
    name: "HISTAMINES",
    link: "https://player.vimeo.com/video/863721571?h=c5228f28f0",
  },
  {
    name: "CARBS",
    link: "https://player.vimeo.com/video/863721423?h=eb7b376288",
  },
  {
    name: "FATS",
    link: "https://player.vimeo.com/video/863721495?h=3391507d3f",
  },
  {
    name: "PROTEIN",
    link: "https://player.vimeo.com/video/863721697?h=bed09f1996",
  },
  {
    name: "SATURATED FATS",
    link: "https://player.vimeo.com/video/863721787?h=32b39d92c9",
  },
  {
    name: "PUFAS",
    link: "https://player.vimeo.com/video/863721752?h=2677ec20dd",
  },
  {
    name: "MUFAS",
    link: "https://player.vimeo.com/video/863721649?h=54f36a7a4e",
  },
  {
    name: "MICROBIOME",
    link: "https://player.vimeo.com/video/863721537?h=e5cd0127af",
  },
  {
    name: "VITAMIN B12",
    link: "https://player.vimeo.com/video/863766853?h=c5061a71eb",
  },
  {
    name: "VITAMIN B6",
    link: "https://player.vimeo.com/video/863766906?h=9a5bb3c1e9",
  },
  {
    name: "VITAMIN B1 / THIAMINE",
    link: "https://player.vimeo.com/video/863766895?h=4460b6ac58",
  },
  {
    name: "Vitamin B1",
    link: "https://player.vimeo.com/video/863766895?h=4460b6ac58",
  },
  {
    name: "FOLATE (B9)",
    link: "https://player.vimeo.com/video/863766927?h=8da9071278",
  },
  {
    name: "CHOLINE",
    link: "https://player.vimeo.com/video/863766955?h=dde3c5b0ad",
  },
  {
    name: "VITAMIN D",
    link: "https://player.vimeo.com/video/864088511?h=ed1789cf4d",
  },
  {
    name: "VITAMIN K",
    link: "https://player.vimeo.com/video/864088427?h=5b9a4151ac",
  },
  {
    name: "VITAMIN A",
    link: "https://player.vimeo.com/video/864088476?h=51dde6eb6f",
  },
  {
    name: "IRON",
    link: "https://player.vimeo.com/video/864088559?h=6a7aa75c36",
  },
  {
    name: "SELENIUM",
    link: "https://player.vimeo.com/video/864088610?h=4b88a82c6a",
  },
  {
    name: "VITAMIN C",
    link: "https://player.vimeo.com/video/866986604?h=8f2d4c74c9",
  },
  {
    name: "PHASE 1",
    link: "https://player.vimeo.com/video/866986628?h=1a688fd5fa",
  },
  {
    name: "METHYLATION",
    link: "https://player.vimeo.com/video/867282525?h=6dab999b0a",
  },
  {
    name: "ACETYLATION",
    link: "https://player.vimeo.com/video/866986651?h=910657bb11",
  },
  {
    name: "MOLD",
    link: "https://player.vimeo.com/video/867282738?h=f5b2ce6c18",
  },
  {
    name: "LYME",
    link: "https://player.vimeo.com/video/867282654?h=b29c760d1e",
  },
  {
    name: "ENVIROTOXINS",
    link: "https://player.vimeo.com/video/867282617?h=78521383ef",
  },
  {
    name: "METALS",
    link: "https://player.vimeo.com/video/867282691?h=9fbbf21f17",
  },
  {
    name: "ANTIOXIDANTS",
    link: "https://player.vimeo.com/video/869716567?h=22dcfa0f10",
  },
  {
    name: "IMMUNE FUNCTION",
    link: "https://player.vimeo.com/video/869716661?h=e47640921d",
  },
  {
    name: "SODIUM SENSITIVITY",
    link: "https://player.vimeo.com/video/869716707?h=af0713d45b"
  },
  {
    name: "SODIUM SENSITIVITY",
    link: "https://player.vimeo.com/video/869716707?h=af0713d45b"
  },
  {
    name: "NITRIC OXIDE",
    link: "https://player.vimeo.com/video/869716756?h=5104a614d3"
  },
  {
    name: "CAFFEINE",
    link: "https://player.vimeo.com/video/869716793?h=8438682d9b"
  },
  {
    name: "ONSET",
    link: "https://player.vimeo.com/video/869716610?h=d10ab6e81d"
  },
  {
    name: "CIRCADIAN RHYTHM",
    link: "https://player.vimeo.com/video/869716641?h=be31d3f9e3"
  },
  {
    name: "DURATION",
    link: "https://player.vimeo.com/video/869716686?h=1ff6c198f9"
  },
  {
    name: "DISRUPTION",
    link: "https://player.vimeo.com/video/869716737?h=1c12fee79b"
  },
  {
    name: "MOVEMENT",
    link: "https://player.vimeo.com/video/869716772?h=2325353bcd"
  },
  {
    name: "MELATONIN",
    link: "https://player.vimeo.com/video/869716816?h=11f8a99a63"
  }, {
    name: "CORTISOL",
    link: "https://player.vimeo.com/video/872002248?h=a94c72db4f"
  },
  {
    name: "MOOD REGULATION",
    link: "https://player.vimeo.com/video/872002266?h=1fd32e07bb"
  }, {
    name: "HUNGER SIGNALING",
    link: "https://player.vimeo.com/video/875713318?h=6bc9b6036e"
  },
  {
    name: "SUGAR RESPONSE",
    link: "https://player.vimeo.com/video/905013418?h=828e7a5eca"
  },
  {
    name:"INTENSITY",
    link:"https://player.vimeo.com/video/875713453?h=c2c7d1853d"
  },
  {
    name: "ENDURANCE",
    link: "https://player.vimeo.com/video/875713482?h=73628231b8"
  },
  {
    name: "SHORT BURST POWER",
    link: "https://player.vimeo.com/video/875713509?h=243fa16a4a"
  },
  {
    name: "STRENGTH TRAINING",
    link: "https://player.vimeo.com/video/875713538?h=9a4ce57b49"
  },
  {
    name: "SOFT TISSUE",
    link: "https://player.vimeo.com/video/875713433?h=92415775ca"
  },
  {
    name: "RECOVERY",
    link: "https://player.vimeo.com/video/875713413?h=4c47c961eb"
  },
  {
    name:"THYROID",
    link:"https://player.vimeo.com/video/878805472?h=ba131e95ba"
  },
  {
    name:"ESTROGEN",
    link:"https://player.vimeo.com/video/878805492?h=70aec7d663"
  },
  {
    name:"TESTOSTERONE",
    link:"https://player.vimeo.com/video/878805543?h=5e346e1667"
  },
  {
    name:"SWEETS AND SNACKING",
    link:"https://player.vimeo.com/video/905013418?h=828e7a5eca&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  },
  {
    name: "BLOOD SUGAR REGULATION",
    link:"https://player.vimeo.com/video/875713377?h=1e02927459",
  },
  {
    name: "PLANT STEROLS",
    link: "https://player.vimeo.com/video/904821336?h=e542406d8b",
  }
];



export const FORMOPTIONS: Option[] = [
   {value:"AL", label:"Alabama"},
	 {value:"AK", label:"Alaska"},
	 {value:"AZ", label:"Arizona"},
	 {value:"AR", label:"Arkansas"},
	 {value:"CA", label:"California"},
	 {value:"CO", label:"Colorado"},
	 {value:"CT", label:"Connecticut"},
	 {value:"DE", label:"Delaware"},
	 {value:"DC", label:"District Of Columbia"},
	 {value:"FL", label:"Florida"},
	 {value:"GA", label:"Georgia"},
	 {value:"HI", label:"Hawaii"},
	 {value:"ID", label:"Idaho"},
	 {value:"IL", label:"Illinois"},
	 {value:"IN", label:"Indiana"},
	 {value:"IA", label:"Iowa"},
	 {value:"KS", label:"Kansas"},
	 {value:"KY", label:"Kentucky"},
	 {value:"LA", label:"Louisiana"},
	 {value:"ME", label:"Maine"},
	 {value:"MD", label:"Maryland"},
	 {value:"MA", label:"Massachusetts"},
	 {value:"MI", label:"Michigan"},
	 {value:"MN", label:"Minnesota"},
	 {value:"MS", label:"Mississippi"},
	 {value:"MO", label:"Missouri"},
	 {value:"MT", label:"Montana"},
	 {value:"NE", label:"Nebraska"},
	 {value:"NV", label:"Nevada"},
	 {value:"NH", label:"New Hampshire"},
	 {value:"NJ", label:"New Jersey"},
	 {value:"NM", label:"New Mexico"},
	 {value:"NY", label:"New York"},
	 {value:"NC", label:"North Carolina"},
	 {value:"ND", label:"North Dakota"},
	 {value:"OH", label:"Ohio"},
	 {value:"OK", label:"Oklahoma"},
	 {value:"OR", label:"Oregon"},
	 {value:"PA", label:"Pennsylvania"},
	 {value:"RI", label:"Rhode Island"},
	 {value:"SC", label:"South Carolina"},
	 {value:"SD", label:"South Dakota"},
	 {value:"TN", label:"Tennessee"},
	 {value:"TX", label:"Texas"},
	 {value:"UT", label:"Utah"},
	 {value:"VT", label:"Vermont"},
	 {value:"VA", label:"Virginia"},
	 {value:"WA", label:"Washington"},
	 {value:"WV", label:"West Virginia"},
	{value:"WI", label:"Wisconsin"},
	{value:"WY", label:"Wyoming"}
];














