import user from "state/user";
import {SUBLEVELVIDEOS } from "constants/index";

type ArgType = {
  [key: string]: {};
};

export const sortString = ((input:string) => {
  return input.toLowerCase().split("").sort().join("");
})

export const calculateTotalPoints = (
  data: ArgType,
  isSubCategory?: boolean
) => {
  let totalPoints = 0;
  if (isSubCategory) {
    for (let [, value] of Object.entries(data)) {
      //@ts-ignore
      totalPoints += value.total_risk_value;
    }
    return totalPoints;
  } else {
    for (let [, value] of Object.entries(data)) {
      for (let [, _value] of Object.entries(value)) {
        //@ts-ignore
        totalPoints += _value.total_risk_value;
      }
    }
    return totalPoints;
  }
};

export const calculateTotalRiskSum = (data: any) => {
  let totalRisk = 0;
  let total_points = 0;

  data.forEach((item: any) => {
    let risk_value =typeof item.micronutrient.risk_value.find((value:any) => sortString(value.risk) === sortString(item.risk_value)) !== 'undefined'
      ? item.micronutrient.risk_value.find((value:any) => sortString(value.risk) === sortString(item.risk_value)).value 
      : 0
    
    total_points+=1;
    totalRisk+=(risk_value > 2 ? 2 : risk_value)*0.50
  });

  return `${((totalRisk/total_points) * 100)}`;
};

export const TotalRiskCalculator = (data:any ,key2:any) => {
  let totalRisk = 0;
  let total_points = 0;

  // get all subcategories of the given category
  const subcategories = data.subcategories.filter((item:any) => item.category === key2);
  const filteredRecords = data.records.filter((rec:any) => subcategories.some((sub:any) => sub.id === rec.subcategory));

  filteredRecords.forEach((item: any) => {
    totalRisk+=(typeof item.micronutrient.risk_value.find((value:any) => sortString(value.risk) === sortString(item.risk_value)) !== 'undefined' ?
          item.micronutrient.risk_value.find((value:any) => sortString(value.risk) === sortString(item.risk_value)).value : 0)*0.50
    total_points+=1;
  });

  return `${((totalRisk/total_points) * 100)}`;
};

export const getSubCategoryLinks = (category:string, title:string)=>{
  // if(category === 'SLEEP') return `SLEEP/ONSET`
  // if(category === 'STRESS RESPONSE') return `STRESS%20RESPONSE/CORTISOL`
  // if(category === 'HORMONES') return `HORMONES/THYROID`
  return `${category}/${title}`
}


